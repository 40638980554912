/* eslint-disable  @typescript-eslint/no-non-null-assertion */import {
  Image as DatoImage,
  ResponsiveImageType,
  renderMetaTags,
  TitleMetaLinkTag,
  StructuredText,
  StructuredTextDocument,
} from 'react-datocms';

import { datoFetch } from '../services/datocms/fetch';
import Text from '../components/display/Text';
import Navigator from '../layouts/Navigator';

import styles from './home.module.scss';

import Head from 'next/head';
import LoginButton from '../components/buttons/LoginButon';
import VideoParagraph from '../components/display/ImageParagraph';
import Link from 'next/link';
import Divider from '../components/display/Divider';
import Image from 'next/image';
import clsx from 'clsx';
import SwiperComponent from '../components/swiper';

import { FeaturedCardContent } from '../components/featuredBlogCard';
import { useMediaQuery } from '@mui/material';
import {featuredCardType} from './resources';
const desktopMediaHeight = 420;
const desktopMediaWidth = 521;

type ResourcesPage = {
  readMoreText: string;
  readingDurationText: string;
  clock: { url: string };
  categoryFile: { url: string };
};
type QA = {
  answer: string;
  question: string;
  id: string;
  richAnswer?:StructuredTextDocument;
};
interface ImageParagraph {
  id: string;
  title: string;
  blurb: string;
  image: { alt: string; responsiveImage: ResponsiveImageType };
}
interface VideoParagraph {
  id: string;
  title: string;
  blurb: string;
  image: { url: string; alt: string };
}
interface HomePageProps {
  title: string;
  subtitle: string;
  startButtonText: string;
  shareLoadSaveTime: ImageParagraph[];
  featuresPartTitle: string;
  featuresFooterText: string;
  featuresButtonText: string;
  featuresPartVideoParagraph: VideoParagraph[];
  seo: TitleMetaLinkTag[];
  partnershipTitle: string;
  partnershipBlurb: string;
  partnershipMember: {
    width: number;
    height: number;
    image: { alt: string; url: string };
  }[];
  seamlessIntegrationTitle: string;
  seamlessIntegrationBlurb: string;
  seamlessIntegrationBlurbText: StructuredTextDocument;
  seamlessIntegrationVideo: {
    alt: string;
    url: string;
  };
  featuredBlogsTitle: string;
  faqTitle: string;
  faqQa: QA[];
}

const QUERY = `query HomePage {
    homePage {
     seo: _seoMetaTags {
        tag
        content
        attributes
      }
        title
        subtitle
        startButtonText
        shareLoadSaveTime {
          blurb
          image {
            alt
            responsiveImage(imgixParams: { fit: crop, auto: format }) {
                srcSet
                webpSrcSet
                sizes
                src
                width
                height
                aspectRatio
                alt
                title
                base64
              }
          }
          title
        }
        featuresPartTitle
        featuresPartVideoParagraph {
          id
          title
          blurb
          image{
           url
           alt
          }
        }
        featuresFooterText
        featuresButtonText
        partnershipTitle
        partnershipBlurb
        partnershipMember {
          width
          height
          image {
            alt
            url
          }
        }
        seamlessIntegrationTitle
        seamlessIntegrationBlurb
        seamlessIntegrationBlurbText {
          value
        }
        seamlessIntegrationVideo {
            alt
            url
         }
         featuredBlogsTitle
         faqTitle
         faqQa {
           answer
           richAnswer{
            value
           }
           question
           id
         }
    }
    lastTwoFeaturedBlog:allBlogs(first: "2", skip: "0", orderBy: _createdAt_DESC, filter: {featured: {eq: true}}) {
      id
      title
      subtitle
      author
      slug
      publishedDate:_firstPublishedAt
      category{
        categoryName
      }
      minutesReading
      featuredImage {
          alt
          responsiveImage(imgixParams: { fit: crop, auto: format }) {
            srcSet
            webpSrcSet
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
            base64
          }
      }
  }
  resourcePage:resourcePage {
    readMoreText
    categoryFile {  url }
    clock {  url }
    readingDurationText
  }

  }`;

export async function getStaticProps(context: { preview?: boolean }) {
  const data = await datoFetch({
    query: QUERY,
    preview: context.preview,
  });
  if (!data?.homePage) {
    // TODO - Log errors
    throw new Error('Failed to fetch new data, serving cached content');
  }

  return {
    props: {
      content: data.homePage,
      lastTwoFeaturedBlog: data.lastTwoFeaturedBlog,
      resourcePage: data.resourcePage,
    },
    revalidate: 60 * 60, // Revalidate Home page every 60 minutes at most
  };
}

function HomePage(props: {
  content: HomePageProps;
  lastTwoFeaturedBlog: Array<featuredCardType>;
  resourcePage: ResourcesPage;
}): React.ReactElement {
  const { content } = props;
  const isMobile = useMediaQuery('(max-width:1000px)');

  const GetStartedBtn = () => (
    <LoginButton
      text={`${content.startButtonText}`}
      className={styles.titleBtn}
      event='heroRegisterButton'
    />
  );

  const ImageParagraph = (props: ImageParagraph) => {
    return (
      <div className={styles.ImageParagraphWrapper}>
        <div className={styles.imgPrgTextWrapper}>
          <Text
            type='light'
            size='h2'
            title={props.title}
            textAlign='start'
            containerStyle={
              isMobile ? styles.mobileParagraph : styles.paragraph
            }
            paddingBottom={!isMobile ? '24px' : '0'}
          />
          <Text
            type='light'
            size='p1'
            title={props.blurb}
            textAlign='start'
            containerStyle={
              isMobile ? styles.mobileParagraph : styles.paragraph
            }
          />
        </div>
        <div className={styles.image}>
          <DatoImage data={props.image.responsiveImage} />
        </div>
      </div>
    );
  };
  const Qa = (props: QA) => {
    return (
      <div className={styles.faqCard}>
        <Text
          type='light'
          size='subH1'
          title={props.question}
          textAlign='start'
          containerStyle={styles.fullWidth}
        />
        <Text
          type='light'
          size='p2'
          title={props.richAnswer ? (<StructuredText data={props.richAnswer}/> as unknown as string) : props.answer}
          textAlign='start'
          containerStyle={styles.fullWidth}
        />
      </div>
    );
  };
  const faqs = content.faqQa.map((category) => {
    return (
      <div key={category.id}>
        <Qa {...category} />
      </div>
    );
  });
  const posts = props.lastTwoFeaturedBlog.map((post) => {
    return (
      <div className={styles.featuredCard} key={post.id}>
        <FeaturedCardContent
          homePage
          readingDurationText={props.resourcePage.readingDurationText}
          isMobile={isMobile}
          readMoreText={props.resourcePage.readMoreText}
          clockUrl={props.resourcePage.clock.url}
          categoryFileURL={props.resourcePage.categoryFile.url}
          featuredImage={post.featuredImage}
          id={post.id}
          categoryName={post.category.categoryName}
          minutesReading={post.minutesReading}
          slug={post.slug}
          author={post.author}
          subtitle={post.subtitle}
          title={post.title}
          key={post.id}
          publishedDate={post.publishedDate}
        />
      </div>
    );
  });

  const ShareLoadSaveTime = content.shareLoadSaveTime.map((category) => {
    return (
      <div key={category.id}>
        <ImageParagraph {...category} />
      </div>
    );
  });
  const FeaturesImgPrg = content.featuresPartVideoParagraph.map((category) => {
    return (
      <div key={category.id}>
        <VideoParagraph
          title={category.title}
          body={category.blurb}
          image={category.image.url}
          imageHeight={desktopMediaHeight}
          imageWidth={desktopMediaWidth}
          imageAlt={category.image.alt}
          isAnimation
        />{' '}
      </div>
    );
  });
  const logos = content.partnershipMember.map((item) => {
    return (
      <div key={item.image.alt} className={styles.logoWrapper}>
        <Image
          src={item.image.url}
          alt={item.image.alt}
          width={item.width}
          height={item.height}
        />
      </div>
    );
  });

  return (
    <>
      <Head>{renderMetaTags(content.seo)}</Head>
      <Navigator>
        <div className={styles.wrapper}>
          <div id='title-container' className={styles.titleContainer}>
            <div className={styles.titleBlock}>
              <h1 className={styles.title}>
                <span>An Intelligent</span>{' '}
                <span className={styles.underline}>Physician Assistant</span>
              </h1>
              <Text
                size='sudoH1'
                type='dark'
                title='at the Point of Care'
                textAlign='center'
              />
              <div className={styles.titleBodyContainer}>
                <Text
                  textAlign='center'
                  size='p1'
                  type='dark'
                  title={content.subtitle}
                />
              </div>
              <GetStartedBtn />
            </div>
          </div>

          <div className={styles.content}>
            <div className={styles.container}>{ShareLoadSaveTime}</div>
            <Divider />
            <div className={styles.container}>
              <div className={styles.titleWrapper}>
                <Text
                  size='sudoH1'
                  type='light'
                  title={content.featuresPartTitle}
                  textAlign='center'
                />
              </div>
              <div className={styles.feauturesVidepPrgContainer}>
                {FeaturesImgPrg}
              </div>
              <div className={styles.learnMoreContainer}>
                <Text
                  size='p1'
                  type='light'
                  paddingTop='129px'
                  textAlign='center'
                  title={content.featuresFooterText}
                />
                <div className={styles.featureLearnMore}>
                  <Link href='/features' passHref>
                    <button className={styles.learnMoreBtn}>
                      {content.featuresButtonText}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <Divider />
            <div className={styles.container}>
              <Text
                size='sudoH1'
                type='light'
                title={content.partnershipTitle}
                textAlign='center'
              />
              <Text
                size='p1'
                type='light'
                title={content.partnershipBlurb}
                paddingTop='50px'
                paddingBottom='76px'
                paddingLeft='30px'
                paddingRight='30px'
                textAlign='center'
              />
              <div className={styles.logosWrapper}>{logos}</div>
            </div>
            <Divider />
            <div className={styles.container}>
              <div className={clsx(styles.featureContainer, styles.compatible)}>
                <VideoParagraph
                  containerStyle={styles.titleRtl}
                  containerTextStyle={styles.compatibleTextContainer}
                  title={content.seamlessIntegrationTitle}
                  body={<StructuredText data={content.seamlessIntegrationBlurbText}/> as unknown as string}
                  image={content.seamlessIntegrationVideo.url}
                  imageHeight={desktopMediaHeight}
                  imageWidth={desktopMediaWidth}
                  imageAlt={content.seamlessIntegrationVideo.alt}
                  isAnimation
                />
              </div>
            </div>
            <Divider />
            <div className={styles.container}>
              <Text
                size='h2'
                type='light'
                title={content.featuredBlogsTitle}
                textAlign='center'
                containerStyle={styles.titleWrapper}
              />
              {!isMobile ? (
                <div className={styles.featuredCardsWrapper}>{posts}</div>
              ) : (
                <div className={styles.swiperComponentContainer}>
                  <SwiperComponent
                    renderItem={(post) => {
                      return (
                        <div
                          className={styles.featuredCardMobile}
                          draggable={false}
                        >
                          <FeaturedCardContent
                            homePage
                            readingDurationText={
                              props.resourcePage.readingDurationText
                            }
                            isMobile={isMobile}
                            readMoreText={props.resourcePage.readMoreText}
                            clockUrl={props.resourcePage.clock.url}
                            categoryFileURL={
                              props.resourcePage.categoryFile.url
                            }
                            featuredImage={post.featuredImage!}
                            id={post.id}
                            categoryName={post.category.categoryName}
                            minutesReading={post.minutesReading}
                            slug={post.slug}
                            author={post.author}
                            subtitle={post.subtitle}
                            title={post.title}
                            publishedDate={post.publishedDate}
                          />
                        </div>
                      );
                    }}
                    items={props.lastTwoFeaturedBlog.map((post) => ({
                      featuredImage: post.featuredImage,
                      id: post.id,
                      category: post.category,
                      minutesReading: post.minutesReading,
                      slug: post.slug,
                      subtitle: post.subtitle,
                      title: post.title,
                      publishedDate: post.publishedDate
                    }))}
                  />
                </div>
              )}
            </div>
            <Divider />
            <div className={styles.container}>
              <Text
                size='sudoH1'
                type='light'
                title={content.faqTitle}
                textAlign='center'
                containerStyle={styles.titleWrapper}
              />
              <div className={styles.faqCards}>{faqs}</div>
            </div>
          </div>
        </div>
      </Navigator>
    </>
  );
}

export default HomePage;
