 export const nth = function(d:number) {
  if(d < 10) {
    d = d % 10;
  }
  if (d > 3 && d < 21) return `${d}th`;
    switch (d % 10) {
      case 1:  return `${d}st`;
      case 2:  return `${d}nd`;
      case 3:  return `${d}rd`;
      default: return `${d}th`;
    }
}
  
 export const timeConverter = (T:string) => {
    const time = new Date(T).toDateString().split(' ').slice(1)
    return [time[0], `${nth(time[1] as unknown as number)},`, time[2]].join(' ')
  }