import clsx from 'clsx';
import Image from 'next/image';

import Text from '../display/Text';

import styles from './ImageParagraph.module.scss';

interface Props {
  title: string;
  body: string;
  image: StaticImageData | string;
  imageAlt: string;
  // By default the image will be fixed to its actual size, but it can be resized via these values
  imageWidth?: number;
  imageHeight?: number;
  containerStyle?: string;
  isAnimation?: boolean;
  textAlign?: 'start' | 'end' | 'center';
  containerTextStyle?: string;
}

export default function ImageParagraph(props: Props) {
  const {
    title,
    body,
    image,
    imageAlt,
    imageWidth,
    imageHeight,
    containerStyle,
    isAnimation,
    textAlign,
    containerTextStyle
  } = props;

  return (
    <div className={clsx([styles.container, containerStyle])}>
      <div className={clsx([styles.textContainer, containerTextStyle])}>
      <Text
          size="h2"
          type="light"
          title={title}
          containerStyle={styles.paragraph}
          paddingBottom="24px"
          textAlign={textAlign ? textAlign : 'start'}
        />
        { body ?
          <Text
            size="p1"
            type="light"
            title={body}
            containerStyle={styles.paragraph}
            textAlign={textAlign ? textAlign : 'start'}
          /> : null }
      </div>
      <div>
        <div className={styles.desktop}>
          { !isAnimation ? <div className={styles.image}>
            <Image
              src={image}
              alt={imageAlt}
              width={imageWidth}
              height={imageHeight}
              layout='fixed'
            />
          </div> :
          <video autoPlay loop muted playsInline aria-label={imageAlt} style={{ width: imageWidth, height: imageHeight }}>
            <source src={image as string} type="video/mp4"/>
          </video>
        }
        </div>
        <div className={styles.mobile}>
          { !isAnimation ?
            <Image
              src={image}
              alt={imageAlt}
            /> :
            <video autoPlay loop muted playsInline aria-label={imageAlt} width="100%">
              <source src={image as string} type="video/mp4"/>
            </video>
          }
        </div>
      </div>
    </div>
  );
}
